import clsx from "clsx";
import Etf from "./Pages/ETF";
import Login from "./Pages/Login";
import Payment from "./Pages/Payment";
import Profile from "./Pages/Profile";
import {Provider} from "react-redux";
import Indices from "./Pages/Indices";
import {Toaster} from 'react-hot-toast';
import Register from "./Pages/Register";
import NotFound from "./Pages/NotFound";
import {store, persistor} from "./store";
import WatchList from "./Pages/WatchList";
import {useEffect, useState} from "react";
import CheckInbox from "./Pages/CheckInbox";
import LandingPage from "./Pages/LandingPage";
import ProfileInfo from "./Pages/ProfileInfo";
import {Route, Routes, useLocation} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import MarketOverview from "./Pages/MarketOverview";
import {ThemeContext} from "./Context/ThemeContext";
import CompanyOverview from "./Pages/CompanyOverview";
import ConfirmChangeEmail from "./Pages/ConfirmChangeEmail";
import {PersistGate} from "redux-persist/integration/react";
import WebsiteContainer from "./Container/WebsiteContainer";
import ConfirmChangePassword from "./Pages/ConfirmChangePassword";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import ScreenerTab from "./Container/Screener/ScreenerTab";
import Calender from "./Pages/Calender";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import 'react-tooltip/dist/react-tooltip.css'


import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import GuruInvestor from "./Pages/GuruInvestor";
import CompareStock from "./Pages/CompareStock";
import Maintenance from "./Pages/Maintenance";

const theme = createMuiTheme({
    typography: {fontFamily: 'Poppins',},
    palette: {
        secondary: {
            main: '#FF7A00',
        },
    }
})

const queryClient = new QueryClient()


function App() {
    const [darkTheme, setDarkTheme] = useState(false)
    const handleThemeChange = () => {
        setDarkTheme(!darkTheme)
    }

    const [webTheme, setWebTheme] = useState('light')

    const changeTheme = (theme) => {
        setWebTheme(theme)
        document.documentElement.setAttribute("data-theme", theme);
    }

    const [cookieLang, setCookieLang] = useState('English')

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeContext.Provider
                        value={{darkTheme, handleThemeChange, webTheme, changeTheme}}
                    >
                        <WebsiteContainer>
                            <MuiThemeProvider theme={theme}>
                                <Toaster/>
                                <Routes>
                                    {/* Accounts */}
                                    <Route exact path={"/login/:code/"} element={<Login/>}/>

                                    <Route exact path={"/login"} element={<Login/>}/>

                                    <Route exact path={"/register/"} element={<Register/>}/>

                                    <Route exact path={"/check-inbox/"} element={<CheckInbox/>}/>

                                    <Route exact path={"/profile-info/"} element={<ProfileInfo/>}/>

                                    <Route exact path={"/confirm-change-password/:uid/:token/"}
                                           element={<ConfirmChangePassword/>}/>

                                    <Route exact path={"/confirm-change-email/:code/"} element={<ConfirmChangeEmail/>}/>

                                    <Route exact path={"/profile/"} element={<Profile/>}/>

                                    {/* Payments */}
                                    <Route exact path={"/payment/"} element={<Payment/>}/>

                                    {/* Landing Page */}
                                    <Route exact path={"/"} element={<LandingPage/>}/>

                                    {/* App */}
                                    <Route exact path={"/market-overview/"} element={<MarketOverview/>}/>

                                    <Route exact path={"/watchlist/"} element={<WatchList/>}/>

                                    <Route exact path={"/indices/"} element={<Indices/>}/>

                                    <Route exact path={"/indices/:indexcode/"} element={<Indices/>}/>

                                    <Route exact path={'/etfs/'} element={<Etf/>}/>

                                    <Route exact path={"/etfs/:code/:exchange/"} element={<Etf/>}/>

                                    <Route exact path={"/company-overview/:code/:exchange/"}
                                           element={<CompanyOverview/>}/>

                                    <Route exact path={'/compare-stocks/'} element={<CompareStock/>}/>

                                    <Route exact path={'/screener/'} element={<ScreenerTab/>}/>

                                    <Route exact path={'/calendar/'} element={<Calender/>}/>

                                    <Route exact path={'/investor/'} element={<GuruInvestor/>}/>

                                    <Route exact path={'/investor/:name/'} element={<GuruInvestor/>}/>

                                    {/* Page not found */}
                                    <Route path='*' element={<NotFound/>}/>
                                </Routes>
                                <CookieConsent
                                    buttonText="Accept and Continue"
                                    buttonStyle={{
                                        backgroundColor: "transparent",
                                        backgroundImage: "linear-gradient(150deg, #2F78DA 0%, #F2295B 100%)",
                                        color: "white",
                                        fontWeight: 400,
                                        fontSize: 12,
                                        borderRadius: 20,
                                        cursor: "pointer"
                                    }}
                                    style={{background: "#F5F6FA", color: "#0C1B29", fontSize: 12}}
                                >{cookieLang === "English" ?
                                    <>
                                        We use cookies and other tracking technologies to improve your browsing
                                        experience on
                                        our
                                        website, to show you personalized content and targeted ads, to analyze our
                                        website
                                        traffic,
                                        and to understand where our visitors are coming from. By clicking "Accept &
                                        Continue",
                                        you
                                        accept the use of cookies as well our&nbsp;

                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/privacy-policy/'}>
                                            Policies
                                        </a>&nbsp;
                                        and&nbsp;
                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/terms-of-use/'}>
                                            Use of Terms,
                                        </a>&nbsp;
                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/documents/'}>
                                            Read more
                                        </a>
                                    </>
                                    :
                                    <>
                                        Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website so angenehm wie
                                        möglich zu
                                        gestalten, um unsere Produkte auf sie zuzuschneiden, zu verbessern,
                                        zielgerichtete
                                        Werbung anzuzeigen und zu analysieren und zu verstehen, woher unsere Besucher
                                        kommen.
                                        Indem Sie auf „Akzeptieren und fortfahren“ klicken, akzeptieren Sie die
                                        Verwendung von
                                        Cookies sowie unsere&nbsp;
                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/privacy-policy/'}>
                                            Richtlinien
                                        </a>
                                        &nbsp;und&nbsp;
                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/terms-of-use/'}>
                                            Nutzungsbedingungen.
                                        </a>
                                        &nbsp;
                                        <a className={'cookie-link'} target={"_blank"}
                                           href={'https://web.finqube.io/documents/'}>
                                            Weiterlesen.
                                        </a>
                                    </>
                                }. <br/>
                                    <button
                                        className={clsx('btn lang-btn', cookieLang === "English" ? "selected-lang-btn" : "btn-outline-secondary")}
                                        onClick={() => {
                                            setCookieLang('English')
                                        }}
                                    >English
                                    </button>
                                    <button
                                        className={clsx('btn lang-btn', cookieLang === "Deutsch" ? "selected-lang-btn" : "btn-outline-secondary")}
                                        onClick={() => {
                                            setCookieLang('Deutsch')
                                        }}
                                    >Deutsch
                                    </button>
                                </CookieConsent>
                            </MuiThemeProvider>
                        </WebsiteContainer>
                    </ThemeContext.Provider>
                </PersistGate>
            </Provider>

            <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"}/>
        </QueryClientProvider>
    );
}

export default App;

// https://backend.finqube.io

// https://uat.backend.finqube.io


// ,
//     "postbuild": "react-snap"

// "reactSnap": {
//   "puppeteerArgs": [
//     "--no-sandbox",
//     "--disable-setuid-sandbox"
//   ],
//   "puppeteerExecutablePath": "/opt/google/chrome/google-chrome"
// },
