import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import axios from "axios";
import {raise_error} from "../Container/Utils";
import Config from "../Config";
import {useDispatch, useSelector} from "react-redux";
import {Logout} from "../actions/actions";

function ConfirmChangePassword() {

    const formRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const [data, setData] = useState({
        email: "",
        password1: "",
        password2: ""
    })

    const change_text_handler = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        try {
            const uid = location.pathname.split('/')[2]
            const token = location.pathname.split('/')[3]
        } catch (e) {
            toast.error("Invalid Token", {duration: 5000})
            navigate('/login')
        }
    }, [])


    const [showPassword, setShowPassword] = useState(false)

    const changePassword = () => {
        let uid= ''
        let token = ''
        try {
            uid = location.pathname.split('/')[2]
            token = location.pathname.split('/')[3]
        } catch (e) {

        }

        const config = Config()
        if (data.password1 !== data.password2) {
            toast.error("Passwords must match", {duration: 5000})
        } else {
            toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/confirm-change-password/`, {
                uid,
                token,
                ...data
            }, config), {
                loading: 'Changing Password...',
                success: (res) => {
                    navigate('/login')
                    return res.data.message
                },
                error: (err) => {
                    return raise_error(err)
                }
            })
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(Logout())
        }
    }, [])

    return (
        <main style={{position: "relative", overflow: 'hidden'}}>
            <div className={'ornament-2'}/>
            <form ref={formRef}>
                <section
                    style={{
                        backgroundColor: "#0C1B29",
                        height: "100vh",
                        width: '100%',
                        display: 'flex',
                        overflow: 'auto'
                    }}>
                    <div className="container" style={{margin: 'auto'}}>
                        <h1 className={'change-password-heading'}>Forgot Password</h1>
                        <div className="change-password-container">
                            <p className={'input-field-helper-text'}>Email</p>
                            <input value={data.email}
                                   name={"email"}
                                   required={true}
                                   type={'email'}
                                   onChange={change_text_handler}
                                   className={'form-control input-field change-password-email-field mb-2'}/>

                            <p className={'input-field-helper-text'}>Enter New Password</p>
                            <input value={data.password1}
                                   name={"password1"}
                                   type={showPassword ? 'text' : 'password'}
                                   autoComplete={"off"}
                                   required={true}
                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                   title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                   onChange={change_text_handler}
                                   className={'form-control input-field mb-2'}/>

                            <p className={'input-field-helper-text'}>Confirm new Password</p>
                            <input value={data.password2}
                                   name={"password2"}
                                   type={showPassword ? 'text' : 'password'}
                                   autoComplete={"off"}
                                   required={true}
                                   onChange={change_text_handler}
                                   className={'form-control input-field mb-2'}/>

                            <div style={{display: 'flex', margin: "5px 0"}}>
                                <p className={'show-password'}
                                   onClick={() => {
                                       setShowPassword(!showPassword)
                                   }}
                                >Show Password</p>
                            </div>

                            <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                                <button className={"btn reg-control-btn reg-next-btn"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const form = formRef.current;
                                            if (!form.checkValidity()) {
                                                form.reportValidity()

                                            } else {
                                                changePassword()
                                            }
                                        }}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </main>
    );
}

export default ConfirmChangePassword;
